import { LightenDarkenColor } from 'lighten-darken-color'; 

function Dino(props) {
    return (
        <svg height="280px" version="1.1" viewBox="0 0 41.652 42.977" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(45.382 -28.195)">
                <g>
                    <path 
                        id="Spikes" 
                        d="m-34.915 29.583c1.5816-1.94 3.7288-2.0536 1.9461 0.99352l0.73218 1.003c4.3479-1.3925 3.6144 0.85936 0.29218 1.9764l0.0487 1.5054c5.2658 0.20688 2.7645 1.9858-0.09739 1.9965l-0.05053 1.5026c3.8395-0.1458 3.6881 1.2401-0.24165 2.0278l-0.07671 1.3699c3.9157-0.58453 3.0696 1.2643-0.14242 1.844l-0.04777 1.12c2.3864-0.59464 3.6552 0.08523 0.60503 2.3379l1.0984 1.5578c2.8419-3.2052 3.1255-2.1015 3.0256 1.1191l2.331-0.70518c-1.0306-3.4765-0.29068-3.9454 2.2644-1.6313l1.159-0.88111c-0.77212-3.6743 0.69438-4.1522 2.2497-0.82325l1.2284-0.2389c1.1405-4.119 1.8004-3.2072 2.3995 0.21455l1.0823 0.15709c2.9939-3.3447 2.9271-1.5652 1.9855 1.0116l-13.781 3.8957-5.2348-1.8991-1.3878-4.48z" 
                        fill={props.spikes}
                    />
                    <path 
                        id="BackLegs" 
                        d="m-35.064 59.347c0.31722 3.2896-0.85588 7.7942 1.1373 10.547 4.3274 1.5279 4.0907-3.2655 4.4215-5.5269l10.289 1.5022c0.16334 1.313 0.08924 2.7617 0.73692 3.798 2.204 1.1038 3.3869 0.28404 4.4499-0.22674l0.36846-6.8307z" 
                        fill={props.body}
                    />
                    <path 
                        id="Body" 
                        d="m-35.149 60.112c1.8035 2.1616 3.3448 4.4663 7.1733 5.5234-0.55716 2.0176-0.07663 3.4126 0.28693 4.8778 1.0739 1.0832 3.0675 0.58639 4.0888 0.2152l1.6499-4.4474c2.4678 0.56082 4.74-0.24772 7.1016-0.4304l0.81642 4.2333c0.96385 0.26698 1.7993 0.40561 2.2867 0.19607 5.4254-4.4676 4.3443-9.2008 4.1419-13.898 1.6068-2.241 3.7213-3.7791 3.8736-8.0341-1.2924 1.624-3.0639 2.4815-4.9496 3.1562-1.9768-5.2445-9.1144-8.5121-15.064-4.017-4.0288 3.9062-7.0928 2.5682-8.3927-2.0818-0.21263-5.1378 2.0785-12.501-0.63788-15.413-15.012-5.8536-16.343 7.6209-5.4594 8.8154 3.2593 6.6843-2.0525 13.796 3.0845 21.305z" 
                        fill={LightenDarkenColor(props.body, 30)}
                    />
                    <g fill={props.spots}>
                        <path d="m-16.455 51.034c-0.73727-1.0417-3.0611-0.24791-1.9196 1.7224 0.84912 1.4657 4.0536 1.2927 1.9196-1.7224z"/>
                        <path d="m-12.483 51.119c0.74435-0.98138-0.34685-1.9522-1.5837-0.67962-0.48942 0.50353 0.21494 2.4843 1.5837 0.67962z"/>
                        <path d="m-19.386 49.491c0.62388-0.97606-0.96645-2.211-1.9641-0.91065-0.68142 0.88818 0.73591 2.8321 1.9641 0.91065z"/>
                        <path d="m-16.313 47.183c-1.4812-0.22543-1.5965 0.48467-0.02058 1.7458 1.4406 1.1528 2.7444-1.3312 0.02058-1.7458z"/>
                    </g>
                    </g>
                    <g>
                    <g>
                        <circle cx="-38.22" cy="31.158" r=".89578" fill="#f6fefe"/>
                        <circle cx="-41.271" cy="30.755" r=".89578" fill="#fff"/>
                        <circle cx="-38.609" cy="31.443" r=".27263"/>
                        <circle cx="-41.621" cy="31.054" r=".27263"/>
                    </g>
                    <path d="m-41.803 32.793c0.14249 1.1617 1.7619 1.0956 1.8175 0.25964" fill="none" stroke="#000" strokeWidth=".26458px"/>
                </g>
            </g>
        </svg>

    );
}

export default Dino;